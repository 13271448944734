import Box from "@mui/material/Box";
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

export default function MultiCarousel({ autoPlaySpeed, rtl }) {

    const IMAGES = [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Ministry_of_Health_and_Welfare_of_the_Republic_of_Korea_Logo_%28vertical%29.svg/512px-Ministry_of_Health_and_Welfare_of_the_Republic_of_Korea_Logo_%28vertical%29.svg.png",
        "https://www.alzheimers.org.uk/sites/default/files/styles/logo_desktop/public/media/images/image/2023-03/Alzheimers%20Logo%20Desktop.png?itok=UMR8me8i",
        "./logo.png",
    ]

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Box >

            <Carousel responsive={responsive}
                arrows={false}
                swipeable={true}
                showDots={false}
                draggable={true}
                infinite={true}
                autoPlay={true}
                rewind={false}
                autoPlaySpeed={autoPlaySpeed}
                rtl={rtl}
            >
                {IMAGES.map((img, idx) => (
                    <div key={`${img}-${idx}`} style={{ textAlign: 'center' }}>
                        <img
                            src={img}
                            alt={idx}
                            width="120"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: idx === 1 ? '30px' : '0px',

                            }}
                        />
                    </div>
                ))}

            </Carousel>

        </Box>
    );


}