import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export default function InfoSection({ title, subtitle, description, height, media, link, linkText }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <Box
            sx={{
                border: "2px solid #d8e0eb",
                borderRadius: "10px",
                backgroundColor: "#edf1f7",
                // height: height,
                height: "100%",
                p: 1,

                pb: 5,
                pt: 2,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

            }}
        >
            <Typography color="text.primary" sx={{ pt: 2, pl: 3, pr: 3, fontSize: isMobile ? '18px' : '22px', fontWeight: "500", letterSpacing: 1.1 }}>
                ✲ {title}
            </Typography>
            <Typography color="secondary.main" variant="subtitle1" sx={{ pt: 0, pl: 3, pr: 3, }}>
                {subtitle}
            </Typography>
            <Typography color="secondary.main" sx={{
                pl: 3, pt: 2, pr: 3,
                fontSize: isMobile ? '14px' : '18px',
            }}>
                {description}
            </Typography>
            {link && (
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="contained" target="_blank" rel="noopener noreferrer" href={link}>
                        {linkText}
                    </Button>
                </Typography>
            )}

            {media && (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    {media.startsWith("https:") ? (
                        <iframe
                            style={{ borderRadius: "15px", marginTop: "30px", padding: "10px" }}
                            width="85%"
                            height="389"
                            src={media}
                            title="Video"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    ) : (
                        <img
                            alt={"Image of " + title}
                            style={{ height: "100%", width: "100%", borderRadius: "10px" }}
                            src={media}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
}
