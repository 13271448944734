import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from "react";

import theme from '../theme';

function FAQ() {
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const INFO_HEIGHT = isMobile ? "300px" : "250px";

    return (
        <div >

            <Box sx={{ display: "flex", flexDirection: "column", m: 2, mb: 20, pb: 20 }}>

                <Box sx={{ display: "flex", m: 2, mb: 2 }}>

                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '25px' }}>FAQ - 자주하는 질문</Typography>

                </Box>

            </Box>
        </div>
    );
}
export default FAQ;