import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from "react";

function AboutUs() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])


    return (
        <div style={{ marginTop: 20, marginBottom: 20, paddingBottom: 150 }} >

            <Box sx={{ display: "flex", ml: 3, pt: 15, justifyContent: "space-between" }}>
                <Typography color={'primary.main'} variant='overline' sx={{ fontSize: '30px', fontWeight: "bold" }} data-aos="fade-up" data-aos-duration="400" >ABOUT US - 회사 소개 </Typography>

                {isMobile ? null : <img width={80} height={80}
                    data-aos="fade-up" data-aos-duration="600"
                    style={{ marginRight: 10 }}
                    className="img-animation"
                    alt={"loading"}
                    src='./logo.png'></img >}


            </Box>


            <Box sx={{ display: "flex", m: 2, mb: 1 }} data-aos="fade-up" data-aos-duration="700" >
                <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                    ✲ 인사말
                </Typography>
            </Box>
            <Box data-aos="fade-up" data-aos-duration="800">
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>안녕하세요 여러분,</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>브이콜 코리아에 오신 것을 환영합니다. 우리는 간병인, 치매 환자, 자원봉사자분들의 어려운 여정을 함께 나누고자 이곳에 모였습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>가족 간병인들의 약 60% 이상이 하루에 10시간 이상 치매 환자를 돌보는 등 다양한 어려움에 직면하고 있습니다. 많은 경우 요양원을 고려하지만 비용 문제나 거리 때문에 어려움을 겪기도 합니다. 그럼에도 불구하고 사랑하는 이를 돕기 위해 자신의 건강과 활력을 희생하며 노력하고 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>하지만 왜 이렇게 힘들게 살아야 할까요? 왜 일과 친구, 사회를 등지고 이렇게까지 도와야 할까요? 브이콜은 전문적인 간병 서비스를 제공할 수는 없지만, 작게나마 이용 가능한 국가적 지원 서비스를 안내하고, 여러분의 정신 건강을 지키는 데 도움을 드릴 수 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>일상적인 대화는 세계 어느 곳이든 개인의 삶의 질을 높여주는 중요한 역할을 합니다. VCall을 통해 간병인분들은 앞으로 몇 주 동안 더 많은 것을 배우며 훈련을 받은 자원봉사자와 어떤 주제든지 자유롭게 이야기할 수 있습니다. 축구나 낚시에 대해 이야기하거나, 어려운 감정이나 상황을 털어놓을 수도 있습니다. 자원봉사자들은 여러분의 이야기를 경청하며 도움을 줄 준비가 되어 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>VCall은 의료적 치료가 치매의 일부분만을 차지한다는 인식을 바꾸기 위해 지속적으로 높이기 위해 노력하고 있습니다. 치매는 가족 전체가 정서적, 재정적, 사회적으로 어려움을 겪습니다. 자원봉사 전화 서비스는 이러한 어려움을 줄이고자 시작되었습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>알츠하이머 협회의 CEO인 케이트 리와 이와 관련한 주제에 대한 이야기를 나누었습니다. 케이트 리의 도움 덕분에 브이콜 코리아를 서비스할 수 있었습니다. VCall과 함께할 여러분을 진심으로 환영하며, 파트너 자원봉사자 및 보호자들과 함께 활발한 활동해 주시길 바랍니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>감사합니다,</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>현준 대표</Typography>
                </Box>


                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 동반자 전화 서비스 소개
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>VCall은 치매로 인한 어려움을 겪는 모든 분들에게 중요한 지원의 원천이자 긍정적인 변화를 위한 힘이 될 것이며, 도움과 희망을 전달하는 역할을 하고 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>자원봉사는 주로 특정 지역에서 이루어지는 경우가 많지만, 알츠하이머 협회의 자원봉사자는 치매 환자를 돕기 위해 노력하는 전국적인 자선 단체의 일원입니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>알츠하이머 협회는 영국, 웨일스, 북아일랜드에서 주로 활동하며, 1700명 이상의 헌신적인 직원과 약 4000명의 훌륭한 자원봉사자로 구성되어 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>VCall은 이러한 알츠하이머 협회의 한국 파트너입니다. 현재 6명의 직원과 함께 봉사를 시작하고 있으며, 여러분과 VCall 봉사자로 함께하게 되어 매우 기대됩니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>우리는 "알츠하이머병"이라는 이름과는 다르게 모든 유형의 치매 환자와 그 간병인 및 보호자를 지원합니다. 도움 자료에서 치매와 알츠하이머병의 차이점을 자세히 살펴보실 수 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>VCall과 알츠하이머 협회는 치매 환자와 그의 가족들 및 간병인을 지원합니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>조사에 따르면 2025년까지 영국에서는 100만 명이 치매를 앓게 될 것으로 예상되며 (2023년 한국은 96만 명), 수백만 명의 간병인, 배우자, 가족, 친구들도 이에 영향을 받을 것으로 예상하고 있습니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>현재 치료법이 제한적이며 광범위한 치매 지원 (예: 재정적 지원, 사전 치료 계획, 환자와 간병인을 위한 정신 건강 지원 등)을 찾기 어려운 상황에서 치매 환자와 그의 가족들은 필요한 지원을 받지 못하고 어려움을 겪고 있습니다.</Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 도움과 희망 - 알츠하이머 협회 & VCall 전략 2022-27
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>알츠하이머 협회는 치매로 인해 어려움을 겪는 환자와 보호자들이 편견과 불평등에서 벗어나 보다 만족스럽고 두렵지 않는 삶을 영위할 수 있기를 바랍니다. 현재와 미래에 걸쳐 치매로 인한 어려움을 겪는 분들을 지원하여, 그들의 삶을 개선하고 위기 상황에 이르지 않도록 노력하겠습니다. 우리는 지원과 희망을 동시에 제공할 것입니다.</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>치매로 인한 어려움을 겪는 분들이 다음과 같은 상황을 극복할 수 있도록 지원합니다:</Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>
                        <ol style={{ listStyle: 'decimal' }}>
                            <li>진단 받기</li>
                            <li>치매 생활에 대한 적응</li>
                            <li>돌봄에 대한 더 큰 지원 필요</li>
                            <li>병원에 입원 상황</li>
                            <li>대체 가정 필요</li>
                            <li>생의 마지막 순간</li>
                        </ol>
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>우리는 실용적인 조언과 정서적 지원 등 다양한 사람들에게 아낌없는 지원을 제공함으로써 목표를 이뤄나갈 것입니다. 또한, 치매로 어려움을 겪는 분들이 변화의 힘이 될 수 있도록 연구와 영향력을 발휘하며 삶을 변화시킬 긍정적인 혁신을 추구합니다. 우리의 모든 노력은 치매와 공존하는 현실에 대한 깊은 이해를 바탕으로 이루어지고 있습니다.</Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ VCall 전략 2023-2025
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography sx={{ fontSize: '18px' }}>
                        <ol style={{ listStyle: 'decimal' }}>
                            <li>동반자 전화 서비스를 한국의 치매 간병인, 가족, 그리고 환자에게 배포하기.</li>
                            <li>치매 환자뿐만 아니라 보호자도 돌보는 것 역시 그만큼 중요하다는 전인적 치료의 중요성을 알리기 위해 지역 기관과 협력하여 교육하고, 이에 관련된 다양한 국내 지원 제도를 소개합니다.</li>
                            <li>치매에 대한 사회적 편견을 없애고 사회적 복지를 개선하기 위한 공개 토론을 촉진할 것.</li>
                        </ol>
                    </Typography>
                </Box>

                <Box sx={{ m: 5 }}>
                    <Typography sx={{ fontSize: '18px' }}>동반자 전화는 매주 "수다"를 할 수 있는 친근한 서비스입니다. VCall과 우리의 자원봉사자들은 치매 전문가나 행정 담당자가 아닙니다. 따라서 구체적인 치매 지원이나 조언 또는 안내를 제공하지는 않습니다. 어려운 상황에 치매 지원이 필요한 분들은 치매상담 센터(국번 없이 1899-9988)나 지역 보건소의 치매 연결 지원 라인에 문의하시기 바랍니다.</Typography>
                </Box>






            </Box>





        </div >
    );
}
export default AboutUs;