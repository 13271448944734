// requires a loader
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom";

import ClickableInfoSection from '../components/ClickableInfoSection';
import InfoSection from '../components/InfoSection';
import MiniCarousel from '../components/MiniCarousel';
import { auth } from "../services/firebase";
import Telephone from './Telephone.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css";

function Home() {


    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [user, loading, error] = useAuthState(auth);
    const INFO_HEIGHT = isMobile ? "300px" : "250px";

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])
    return (
        <Box sx={{ pb: 25, pt: 5, }}>
            <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true}
                infiniteLoop={true} interval={5000} stopOnHover={false} swipeable={true} emulateTouch={true}
            >
                <Box style={{ height: "40vh" }}>
                    {isMobile ? <img
                        alt="main"
                        style={{ objectFit: "contain", height: "400px", width: "100%" }}
                        src={"./2.png"} /> :
                        <img
                            alt="main"
                            style={{ objectFit: "contain", height: "400px", width: "100%" }}
                            src={"./4.png"} />
                    }
                    {/* <p className="legend" style={{ color: "#fafafa", backgroundColor: "#444" }} ></p> */}
                </Box>

                <Box style={{ height: "40vh" }}>
                    <img
                        alt="1899-9988 전국 치매 안심번호"
                        style={{ height: "100%", width: isMobile ? "100%" : "70%" }}
                        src={Telephone} />
                    {/* <p className="legend" style={{ color: "#fafafa", backgroundColor: "#444" }} ></p> */}
                    {/* <p className="legend" style={{ color: "#fafafa", backgroundColor: "#444" }} >치매상담콜센터</p> */}
                </Box>
            </Carousel >



            <Box sx={{ display: "flex", flexDirection: "column", gap: 10, mt: 15 }} >
                <Grid container sx={{ justifyContent: "space-between" }} spacing={10}>
                    <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center", }} data-aos="fade-up" data-aos-duration="400" >
                        <ClickableInfoSection color='#afb7e3' title={"도움 문의"} description1={"vcallhelp@gmail.com "} isExternal={false} link={"/aboutus"}>
                            <img src="./contact.gif" width={60} />
                        </ClickableInfoSection>
                    </Grid>

                    {/* Ask Tim how to link this button to external website */}
                    <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center", }} data-aos="fade-up" data-aos-duration="700" >
                        <ClickableInfoSection color="#8eb8ed" title={"가까운 치매 시설 찾기"} description1={"VCall의 종합 치매 지원 서비스"} isExternal={false} link={"/resources"}>
                            <img src="./location.gif" width={60} />
                        </ClickableInfoSection>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center", }} data-aos="fade-up" data-aos-duration="1200" >
                        <ClickableInfoSection color="#b7d9e8" title={"치매 네트워크"} description1={"지역 치매 가족/간병인 모임"} isExternal={false} link={"/forum"}>
                            <img src="./group.gif" width={60} />
                        </ClickableInfoSection>

                    </Grid>
                </Grid>
                <MiniCarousel />

                <Box data-aos="fade-up" data-aos-duration="700">
                    <InfoSection title={"동반자 전화 서비스는 무엇인가요?"} subtitle={""} description={"동반자 전화 서비스는 치매 환자와 그 보호자를 대상으로 제공되는 서비스로, 영국 알츠하이머 협회의 시범 프로젝트에서 시작한 서비스입니다. 이 서비스는 코로나19 팬데믹 기간에 치매 환자와 보호자가 겪는 사회적 고립과 외로움 문제를 완화하기 위해 개발되었으며, 팬데믹 이후로도 긍정적인 반응을 얻어 2021년에 영국 내에서 공식적으로 시작되었습니다. 이어서 2023년에는 VCall과 협회와의 협력을 통해 대한민국에도 도입되었습니다. 동반자 전화 서비스는 매주 정기적으로 치매 환자에게 제공되며, 구글 미트를 이용한 전화 방식으로 이루어집니다. 봉사자들은 치매 환자와의 대화에서 상호 간에 이야기하고 싶은 내용을 자유롭게 나누며, 다양한 주제로 친근한 대화를 이끌어냅니다. 주로 치매 환자의 관심사나 일상생활 등의 주제로 대화가 이뤄지며, 이를 통해 치매 환자들의 사회적 교류를 촉진하고 매주 대화를 나눌 수 있는 친근한 공간을 제공하는 것을 목표로 하고 있습니다. 동반자 전화 서비스를 제공하는 VCall과 그 자원봉사자들은 치매 전문가나 행정 담당자가 아니기 때문에 구체적인 치매 관련 지원, 조언 또는 안내를 제공하지는 않습니다. 만약 어려운 상황에 치매 관련 지원이 필요한 경우, 치매상담 센터(국번 없이 1899-9988) 또는 관할 보건소의 치매 연결 지원 라인에 문의하십시오."} height={isMobile ? "500px" : "300px"} />
                </Box>


                <Box data-aos="fade-up" data-aos-duration="700" sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3
                }}>
                    <img
                        style={{ opacity: 0.5, filter: "navy(100%) hue-rotate(180deg)" }}

                        width="60%"
                        src="https://media.istockphoto.com/id/1221052504/vector/customer-support-operator-with-hands-free-and-laptop.jpg?s=612x612&w=0&k=20&c=71axpuM09wU_HB-2rN28W8GrRYe9dsAuti14y5jJ9SU=">
                    </img>
                </Box>

                <Box data-aos="fade-up" data-aos-duration="700">
                    <Grid container sx={{ justifyContent: "space-between", mb: 6 }} spacing={6} gap={isMobile ? 10 : 0}>
                        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", }} data-aos="fade-up" data-aos-duration="400" >
                            <InfoSection title={"동반자 전화 서비스 - 치매 간병인을 위한 전화 봉사 프로그램"} subtitle={""} description={"VCall의 동반자 전화 서비스는 치매 환자의 보호자나 간병인들이 사회와 더 가깝게 연결되고 외로움을 덜 느끼도록 지원하는 비영리 서비스입니다."} />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", }} data-aos="fade-up" data-aos-duration="400" >
                            <InfoSection title={"치매 환자 간병인 및 보호자는 어떻게 동반자 전화 서비스를 사용할 수 있나요?"} description={"치매 간병인(가족 간병인 우선)이시라면 동반자 전화 서비스를 무료로 이용하실 수 있습니다. 치매 환자를 현재 간접적으로(예: 요양원에 환자가 계시는 경우) 돌보고 있지 않더라도 서비스를 이용하실 수 있습니다. 궁금한 점이 있으시면 vcallhelp@gmail.com으로 이메일을 보내주시기 바랍니다. 추가로 컴퓨터 사용이 가능하시고 집에 Wi-Fi가 설치되어 있어야 합니다. 서비스를 요청하고 싶으시다면 아래 버튼을 클릭해 주세요."} />
                        </Grid>
                    </Grid>
                </Box>



                <Box data-aos="fade-up" data-aos-duration="700">


                    <InfoSection title={"동반자 전화 서비스는 치매 간병인을 어떻게 도울까요?"} description={"아래 비디오에서 케빈은 코로나바이러스 봉쇄 기간 동안 요양원에 머무르던 아내와의 분리 생활에 동반자 전화 서비스가 어떻게 도움이 되었는지에 대해 이야기합니다."} height={isMobile ? "800px" : "600px"} media={"https://www.youtube.com/embed/sHZironpM7w"} />
                </Box>

                <Box data-aos="fade-up" data-aos-duration="700">

                    <InfoSection title={"어떤 사람이 동반자 전화 자원봉사자가 될 수 있나요?"} description={"만 16세 이상이며 전과 기록이 없고, 경청하고 대화하는 것을 좋아하는 분이라면 누구나 봉사자가 될 수 있습니다. 추가로 쾌적한 서비스를 위해 기본적으로 컴퓨터를 다룰 줄 알고 안정된 가정용 Wi-Fi 연결이 되어 있어야 활동이 가능합니다. 이에 해당하는 분들의 많은 지원 부탁드리며, 서비스 요청을 하고 싶으시다면 아래 버튼을 클릭해 주세요."} />
                </Box>

                <Box data-aos="fade-up" data-aos-duration="700">

                    <InfoSection title={"동반자 전화 서비스는 왜 필요할까요?"} description={"대한민국 내에는 약 75만 명의 치매 환자와 그들의 가족 구성원이 약 200만 명 가량 존재합니다. 2020년 알츠하이머 협회가 후원한 두 가지 연구에 따르면, 약 3분의 1의 치매 환자와 약 3분의 2의 치매 환자 간병인이 외로움을 느끼는 것으로 밝혀졌습니다. 보호자의 약 절반 가까이(44%)의 간병인들이 중간 정도의 외로움을 겪고있으며, 약 5분의 1(18%)은 심각한 외로움을 느끼고 있다고 보고했습니다. 게다가, 현재 대한민국은 치매 간병인을 지원하는 체계적인 인프라가 부족한 상황입니다. 2023년 질병관리본부 보고서에 따르면, 치매 지원을 위한 국가적 노력은 증가하고 있지만, 시민들의 수준에서 간병인들에게 즉각적이고 실행 가능한 국민적인 지원이 필요합니다."} />
                </Box>

                <Box data-aos="fade-up" data-aos-duration="700" sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3
                }}>
                    <img

                        width="60%"
                        style={{ opacity: 0.5, filter: "navy(100%) hue-rotate(180deg)" }}
                        src="https://media.istockphoto.com/id/1219233915/vector/woman-in-headphones-looks-at-a-laptop.jpg?s=612x612&w=0&k=20&c=4fpBX0VWRD1rprZxPlUymNAEu3vxEo5dXAhbTNu2bE0=">
                    </img>
                </Box>
                <Box data-aos="fade-up" data-aos-duration="700">

                    <Grid container>
                        <InfoSection title={"동반자 전화 안내서"} description={"동반자 전화는 매주 “수다”를 나누며 친근한 서비스를 제공합니다. VCall과 전화 자원봉사자들은 치매 전문가나 행정 요원이 아니기 때문에 구체적인 치매 지원, 조언, 또는 안내를 제공하지 않습니다. 어려운 상황에 치매 지원이 필요한 분들은 치매상담 센터(국번 없이 1899-9988)나 관할 보건소의 치매 연결 지원 라인에 문의하시기 바랍니다."} />
                    </Grid>
                </Box>
                <Box data-aos="fade-up" data-aos-duration="800">
                    <Typography sx={{ display: "flex", justifyContent: "center" }} color="secondary.main">
                        <Button variant="contained" size='large' target="_blank" rel="noopener noreferrer" href={"/login"} sx={{ pl: 3, pr: 3 }}>
                            자원봉사자 지원/고객 서비스 요청
                        </Button>
                    </Typography>
                </Box>
            </Box >
        </Box >
    );
}

export default Home;