import { ThemeProvider } from '@mui/material/styles';
import { Container } from "@mui/system";
import AOS from "aos";
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import "aos/dist/aos.css";
import Footer from "./components/Footer";
import InitialLoading from "./components/InitialLoading";
import Navbar from "./components/Navbar";
import AboutUs from './pages/AboutUs';
import Dashboard from './pages/Dashboard';
import FAQ from './pages/FAQ';
import Forum from './pages/Forum';
import Home from "./pages/Home";
import Login from './pages/Login';
import Profile from './pages/Profile';
import Resources from './pages/Resources';
import theme from "./theme";
function App() {

  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"));


  useEffect(() => {
    AOS.init();
  })
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);


  }, [])


  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ?
          <InitialLoading />
          :
          <>
            <Navbar />
            <Container sx={{
              mt: isMobile ? 10 : 20, backgroundColor: "#fdfdfd", borderRadius: 2,
              borderTopLeftRadius: 2, borderTopRightRadius: 2,
              boxShadow: "#eee 2px 2px 2px 2px",
              mb: 10,

            }}>
              <Box sx={{ pl: isMobile ? 3 : 5, pr: isMobile ? 3 : 5 }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route exact path="/aboutus" element={<AboutUs />} />
                  <Route exact path="/resources" element={<Resources />} />
                  <Route exact path="/faq" element={<FAQ />} />
                  <Route exact path="/forum" element={<Forum />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/profile" element={<Profile />} />

                </Routes>
              </Box>

            </Container>
            <Footer />
          </>
        }
      </ThemeProvider >
    </>
  );
}


export default App;
