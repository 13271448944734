import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
//Title 1 = 25px; Title 2 = 22px; Title 3 = 18px; Text = 16px//
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Resources() {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])

    return (
        <div style={{
            paddingBottom: 200
        }}>



            <Box sx={{ display: "flex", ml: 3, pt: 15, justifyContent: "space-between" }}>
                <Typography color={'primary.main'} variant='overline' sx={{
                    fontSize: isMobile ? '24px' : '30px', fontWeight: "bold"
                }} data-aos="fade-up" data-aos-duration="400" >RESOURCES - 도움 자료</Typography>

                {
                    isMobile ? null : <img width={80} height={80}
                        data-aos="fade-up" data-aos-duration="600"
                        style={{ marginRight: 10 }}
                        className="img-animation"
                        alt={"loading"}
                        src='./logo.png'></img >
                }


            </Box>

            <Box sx={{ ml: 5, mt: 5 }} data-aos="fade-up" data-aos-duration="800">
                <Box>
                    <Typography sx={{ fontSize: '18px' }}>*고객/간병인 - "고객/간병인" 과 "치매 간병 정보" 섹션을 읽으세요.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '18px' }}>*자원봉사자 - "자원봉사자" 섹션을 읽으세요.</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '18px' }}>질문은 vcallhelp@gmail.com 으로 보내주세요.</Typography>
                </Box>
            </Box>

            <Box data-aos="fade-up" data-aos-duration="800">
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 고객/간병인
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="clientsignup"
                            id="clientsignup"
                        >
                            <Typography sx={{ fontSize: '18px' }}>Signing Up - 동반자 전화서비스 요청 방법</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>


                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>고객/간병인 지원 - 10-20 분. </li>
                                <li>서비스 신청 - 10-20 분. </li>
                                <li>신청에서 서비스 시작까지 - 1-2 주.</li>
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                *동반자 전화 서비스는 치매 간병인을 위한 서비스입니다. 질문이 있으시거나 도움이 필요하시면 vcallhelp@gmail.com 로 이메일을 보내주시기 바랍니다. 아래 모든 문항들을 필히 지참하시기 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                *이메일을 보내실떼는 제목을 “고객 - 이름 - 주제”서식으로 써주시기 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>1. 서비스를 요청하실려면 오른쪽 화면 위쪽의 프로필 (회색 동그란 버튼) 을 누르십시오. 아래 화면 이 새로 보일것입니다:</Typography>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./signup1.jpg' ></img >

                            <Typography sx={{ mt: 2, mb: 4 }}>2. 다음, 구글 계정으로 로그인을 하셔야 합니다. 구글계정이 있으시면 아래 남색 버튼을 누르십시오. 구글 계정이 없는 경우 위 하얀 버튼을 누르시고 새로 열린 왭페이지의 지침을 따라 구글 계정을 만든후 위 페이지로 돌아와 남색 버튼을 누르십시오.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>3. 남색버튼을 누른후 아래 화면이 새로 보일것입니다:</Typography>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./signup2.png' ></img >

                            <Typography sx={{ mt: 2, mb: 2 }}>별표가 달려있거나 “고객/간병인”이 써야되는 문항을 지침대로 써주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>“VCall 고객이 되고 싶은 이유와 전화봉사 서비스 로 얻고 싶은 것을 말씀해 주십시오.”</Typography>

                            <Typography sx={{ mt: 2, mb: 4 }}>위의 정보를 사용하여 적합한 자원봉사자와 연결해 드리니 최대한 자세하게 써주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                4. 24 시간 이내 VCall (vcallhelp@gmail.com) 담당자가 서비스 이용 방법에 대한 추가 정보가 담긴 이메일을 보내드립니다. VCall 담당자와 전화 통화가 가능한 날짜와 시간, 전화번호를 적어서 답장해주시기 바랍니다.
                                <li>전화 통화를 통해 서비스 이용법을 알려드리고 질문을 답하겠습니다.</li>
                                <li>통화 중 컴퓨터와 이메일을 써야되니 꼭 지니고 있으세요. </li>
                            </Typography>

                            <Typography sx={{ mt: 2, mb: 4 }}>5. 자원봉사자와 성공적으로 매칭되면 이메일을 보내드립니다. 1-2주 걸릴수있으니 기다려주시면 감사하겠습니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>6. 축하합니다. 당신은 VCall 동반자 전화 서비스 가입에 성공했습니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>7. 이제 서비스 이용법을 배웁시다.</Typography>

                        </AccordionDetails>
                    </Accordion>
                </Box>


                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="clientHow"
                            id="clientHow"
                        >
                            <Typography sx={{ fontSize: '18px' }}>How to use VCall -고객/간병인 VCall 사용법</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>

                            <Typography sx={{ mt: 2, mb: 1 }}>
                                자원봉사자와 의 첫 미팅:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>vcallhelp@gmail.com 에서 “고객 이름 - 자원봉사자와 매칭됨” 이메일을 받을것입니다.</li>
                                <li>즉시 www.vcall-korea.com/resources 에 접속해서 “고객/간병인 - VCall 사용법”을 읽으세요.</li>
                                <li>“미팅 예약” 섹션부터 읽기 시작하세요.</li>
                            </ol>
                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 예약법:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>www.vcall-korea.com 을 접속하세요.</li>
                                <li>홈페이지 우측 상단의 동그란 버튼을 눌러 구글 계정으로 로그인 하세요.</li>
                                <ol style={{ listStyle: 'latin' }}>
                                    <li>꼭 고객/간병인 지원을 했던 같은 계정으로 로그인 하세요.</li>
                                    <li>로그인 방법을 까먹으셨다면 www.vcall-korea.com/resources 의 “고객/간병인 지원 법”을 읽으세요. 도움이 필요하시다면 vcallhelp@gmail.com 으로 이메일을 보내세요.</li>
                                </ol>
                                <li>로그인후 동그란 버튼을 다시 누르세요. 그것이 프로필 페이지 입니다 - 봉사자의 구글 미츠, 되는시간 (온라인 예약 달력) 링크 와 온라인 통화기록 양식이 보일것 입니다.</li>
                                <li>되는시간 링크를 누르세요. 아래와 비슷한 화면이 보일것입니다:</li>

                            </ol>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./clsign1.png' ></img >

                            <li>이벤트 예약을 누르세요.</li>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./clsign2.png' ></img >

                            <li>통화가 가능한 날짜를 누른후 시간을 선택하세요.</li>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./clsign3.png' ></img >

                            <li>지침대로 위 페이지를 완료후 “예약하기”를 누르세요.</li>
                            <li>예약을 완료한후 캘린더에 저장하세요.</li>
                            <li>자동으로 미팅 예약 세부 사항 (예: 날짜, 시간)이 구글 이메일로 갈것입니다. </li>





                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 시작법:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>www.vcall-korea.com 을 접속하세요.</li>
                                <li>홈페이지 우측 상단의 동그란 버튼을 눌러 구글 계정으로 로그인 하세요.</li>
                                <ol>
                                    <li>꼭 고객/간병인 지원을 했던 같은 계정으로 로그인 하세요.</li>
                                    <li>로그인 방법을 까먹으셨다면 www.vcall-korea.com/resources 의 “고객/간병인 지원 법”을 읽으세요. 도움이 필요하시다면 vcallhelp@gmail.com 으로 이메일을 보내세요.</li>
                                </ol>
                                <li>로그인후 동그란 버튼을 다시 누르세요. 그것이 프로필 페이지 입니다.</li>
                                <ol>
                                    <li>봉사자의 구글 미츠, 되는시간 (온라인 예약 달력) 링크 와 온라인 통화기록 양식이 보일것 입니다.</li>
                                </ol>
                                <li>구글 이메일의 받은 편지함에서 되는시간에서 온 예약 이메일에서 미팅 시간을 확인 하세요.</li>
                                <li>약속된 시간 5분전 구글 미츠 링크를 열어 미팅을 시작하세요.</li>
                                <ol>
                                    <li>카메라는 취향에 따라 켜도 안켜도 됩니다.</li>
                                </ol>
                                <li>구글 미트 로비에서 누군가가 기다리고 있다면 들여보내주세요.</li>
                                <li>*일찍 끝내야 될거 같으면 미리 양해를 구하세요. </li>
                            </ol>

                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 중:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>편안한 사교적 대화를 즐기세요!</li>
                                <li>카메라를 켜는게 불편하시면 양해를 구하고 안 켜셔도 됩니다.</li>
                                <li>봉사자는 자신의 주소, 전화번호등 사적 정보를 발설 할수 없습니다. 고객님도 보안상 사적 정보를 발설하지 말아주세요. 대략 어디 사는지는 예기할수 있읍니다.</li>
                                <li>전문적인 지원이 필요하시다면 vcallhelp@gmail.com 에게 이메일을 이름과 용건을 적어서 보내주세요. 고객님의 주소 근처의 치매/간병인 도움 관련 협회/재단/모임 을 찾아서 소개해 드리겠습니다.</li>
                                <ol>
                                    <li>간병인 가족 모임을 찾고 싶으시다면 www.vcall-korea.com 의 “Forum-포럼” 섹션을 이용해보세요. </li>
                                </ol>
                            </ol>


                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 끝내기:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>전화를 마무리 하고 싶은면 편하게 봉사자에게 말하세요.</li>
                                <li>“되는시간” 링크를 이용하여 다음 미팅시간을 잡으세요.</li>
                                <li>빨간 버튼을 눌러 미팅을 끝네세요.</li>
                                <li>프로필로 돌아가 “온라인 통화 기록 양식”을 완료후 제출해주세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"/login"}>
                                        온라인 통화 기록 양식
                                    </Button>
                                </Typography>
                                <ol>
                                    <li>자원봉사자의 이름은 자원봉사자와의 매칭 이 끝난후 vcallhelp@gmail.com 이 보낸 이메일에 있으니 접속하기 쉬운대 저장해주시기 바랍니다.</li>
                                </ol>
                                <li>문제나 문의가 있다면 vcallhelp@gmail.com 에 이메일을 보내세요.</li>
                            </ol>

                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 자원 봉사자
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="aboutRole-content"
                            id="aboutRole-header"
                        >
                            <Typography sx={{ fontSize: '18px' }}>About the Role - 역할 소개</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>
                            <Typography sx={{ mb: 2 }}>
                                전화로 대화하고 다른 사람의 이야기를 경청하는 것을 좋아하신다면 VCall 은 여러분의 연락을 기다리고 있습니다!
                            </Typography>
                            <Typography sx={{ mb: 2 }}>
                                동반자 전화 자원 봉사자는 치매 환자들이 흔히 겪는 외로움을 줄이는 데 도움을 줍니다. 치매 환자와의 연락과 대화를 통해 치매 환자는 사회적 고립감을 덜고 더 나은 유대감을 느낄 수 있습니다.
                            </Typography>
                            <Typography sx={{ mb: 2 }}>
                                치매 환자나 간병인에게 정기적으로(보통 매주) 전화를 걸어 치매 환자가 관심을 갖고 이야기하고 싶은 주제에 대해 대화를 나눕니다. 매번 같은 사람(이상적으로는 2명 이상)과 채팅하고 온라인 양식을 통해 통화에 대한 간단한 피드백을 제공하게 됩니다. 이 역할에서는 조언이나 안내를 제공하지 않으며 치매에 대한 지식이 없어도 되며, 단순히 누군가와 쉽게 사교적인 대화를 나누기만 하면 됩니다.
                            </Typography>
                            <Typography sx={{ mb: 2 }}>
                                동반자 전화 서비스 자원봉사가 어떤 일인지 알고 싶으신가요? 현 봉사자 브라이언을 만나려면 이 링크를 클릭하세요.
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <iframe
                                    style={{ borderRadius: "15px", marginTop: "30px" }}
                                    width="85%"
                                    height="389"
                                    src={"https://www.youtube.com/embed/sHZironpM7w"}
                                    title="Video"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                관심이 있으신가요? 자세한 내용은 다음 연락처 (vcallhelp@gmail.com) 로 문의하세요.
                            </Typography>
                            <Typography sx={{ mb: 2 }}>
                                다음과 같은 경우 이 역할에 적합할 것입니다:
                                <li>치매 환자의 사회적 고립을 줄이고자 하는 동기가 있는 경우.</li>
                                <li>전화 사용에 자신감이 있고 독립적으로 일할 수 있습니다.</li>
                                <li>당신은 좋은 의사 소통 기술을 가지고 있고, 좋은 경청자이며 사람들과 이야기하는 것을 즐깁니다.</li>
                                <li>기본적인 IT 기술이 있고 인터넷에 쉽게 액세스할 수 있습니다.</li>
                                <li>인내심이 있고 유연하며 합의된 통화 일정을 지킬 수 있습니다.</li>
                                <li>만 16세 이상입니다</li>
                            </Typography>
                            <Typography sx={{ mb: 2 }}>
                                온라인 신청서 작성이 어렵다고 해서 지원을 중단하지 마세요. 이메일로 문의하시면 신청 절차를 도와드리겠습니다.  자원봉사 지원팀에 이메일(vcallhelp@gmail.com) 로 문의하실 수 있습니다.
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" onClick={() => { navigate("/login") }}>
                                    지원하기
                                </Button>
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volapplying-content"
                            id="volapplying-header"
                        >
                            <Typography sx={{ fontSize: '18px' }}>Signing Up - 자원봉사자 지원 방법</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>자원봉사자 지원 - 10-20 분. </li>
                                <li>교육 - 3 시간.</li>
                                <li>지원에서 봉사 시작까지 - 1-2 주.</li>
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                *아래 모든 문항을 꼭 지참하시기 바랍니다. 질문이 있으시거나 도움이 필요하시면 vcallhelp@gmail.com으로 이메일을 보내주시기를 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                *이메일을 보내실떼는 제목을 “봉사자 - 이름 - 주제”서식으로 써주시기 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>1. 서비스를 요청하실려면 오른쪽 화면 위쪽의 프로필 (회색 동그란 버튼) 을 누르십시오. 아래 화면 이 새로 보일것입니다:</Typography>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./signup1.jpg' ></img >

                            <Typography sx={{ mt: 2, mb: 4 }}>2. 다음, 구글 계정으로 로그인을 하셔야 합니다. 구글계정이 있으시면 아래 남색 버튼을 누르십시오. 구글 계정이 없는 경우 위 하얀 버튼을 누르시고 새로 열린 왭페이지의 지침을 따라 구글 계정을 만든후 위 페이지로 돌아와 남색 버튼을 누르십시오.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>3. 남색버튼을 누른후 아래 화면이 새로 보일것입니다:</Typography>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./signup2.png' ></img >

                            <Typography sx={{ mt: 2, mb: 2 }}>별표가 달려있거나 자원봉사자가 써야되는 문항을 지침대로 써주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>“VCall 자원봉사자가 되고 싶은 이유와 전화봉사자 로서 얻고 싶은 것을 말씀해 주십시오.” “지원 직무와 관련된 기술 및 경험에 대해 알려주세요.”</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>e.g., 전직 상담원, 학교 동아리, 봉사 경험 등</li>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>위의 정보를 사용하여 적합한 고객/간병인과 연결해 드리니 최대한 자세하게 써주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>4. 자원봉사자는 전화 일정 예약과 구글 미트 관리 의무 가 있습니다. 봉사자는 구글 미트 링크를 만들어 매치된 고객/간병인과 매주 사교적인 대화를 나눌것입니다. 봉사자는 되는시간 계정과 링크를 만들어 고객/간병인과 대화 시간을 조율할수있읍니다. 더 자세한 전화 프로토콜은 "자원봉사자 - 서비스 사용법" 에서 찾을수있읍니다. 아래 안내를 정확히 따라서 양식을 올바르게 체우시기 바랍니다. </Typography>

                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./signup3.png' ></img >

                            <Typography sx={{ mt: 2, mb: 2 }}>구글 미트 링크 만들기:</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>우측상단 “로그인” 누르기. 구글 계정으로 로그인 하기.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup4.png' ></img >
                                <li>왼쪽하단 파란 “새 회의” 버튼 누르기.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup5.png' ></img >
                                <li>“나중에 진행할 회의 만들기” 누르기.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={350}
                                    src='./signup6.png' ></img >

                                <li>회의 링크를 복사하여 VCall 로 돌아와 아래 박스에 붙여넣으세요.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup7.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>되는시간 (캘린더) 계정과 링크 만들기기:</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>회원가입 을 누르세요.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup8.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>“Sign up with Google”(구글로 사인업 하세요) 를 누르세요. 봉사자가 VCall 에 로그인 한 이메일을 쓰세요.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup9.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>되는시간이 구글 계정을 접속할수있게 허락하세요.</li>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>예약 홈 페이지 주소를 만들세요. 봉사자의 영문 이름을 쓰세요.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup10.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>캘린더를 연동하세요. VCall 은 구글 캘린더와 자주쓰는 캘린더를 연동하는것을 추천합니다.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup11.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>아래 화면이 나올때까지 진행하세요. 매주 일정 예약이 가능한 시간과 요일을 설정해주세요. 시작하기를 누르세요.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup12.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                <li>우측 상단 “가능한 시간”을 눌러 봉사할수있는 시간을 더 상세히 적어놓을수 있습니다.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup13.png' ></img >

                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup14.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                <li>예약 링크 복사후 아래 붙여넣으세요. 고객/간병인 은 그 링크를 누른후 봉사자의 가능한 시간을 조회후 미팅 시간을 잡을수 있읍니다.</li>
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup15.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                5. “제출하기”를 누르세요.
                                < img
                                    style={{ marginTop: 0 }}
                                    alt={"loading"}
                                    width={"100%"}
                                    src='./signup16.png' ></img >
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>6. 24 시간 이내 VCall 담당자가 서비스 이용 방법과 봉사자 교육육에 대한 추가 정보가 담긴 이메일을 보내드립니다. 읽어보시고 도움이 필요하시면 vcallhelp@gmail.com 에 이메일을 보내주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>*이메일을 보내실떼는 제목을 “봉사자 - 이름 - 주제”서식으로 써주시기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>**7. 봉사자 훈련은 “자료 → 자원봉사자 → 의무 교육” 아래 찾을수있읍니다. 꼭 성실하게 다 읽고 'VCall 자원봉사자 환영 핸드북'을 저장하기 쉬운대 보관하세요.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>8. 모든 교육을 맞치고 vcallhelp@gmail.com 에 이메일을 보내세요. 이 이메일을 받아야지만 당신을 고객/간병인과 연결 할것입니다. 담당 관리자가 당신과 전화 미팅을 잡을것이니 미팅이 가능한 시간을 이메일에 포함하기 바랍니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                <li>모든 의무 교육을 끝내고 vcallhelp@gmail.com 에 아래 서식 이메일을 보내시기 바랍니다:</li>
                                <li>의무 교육 끝</li>
                                <li>이름</li>
                                <li>생년월일</li>
                                <li>미팅 가능 날짜와 시간 (한국 시간)</li>
                            </Typography>

                            <Typography sx={{ mt: 2, mb: 2 }}>9. 고객/간병인과 성공적으로 매칭되면 이메일을 보내드립니다. 1-2주 걸릴수있으니 기다려주시면 감사하겠습니다.</Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                중요한 정보가 들어있는 편지이니 기억하기 쉬운데 저장하시기 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>10. 축하합니다. 당신은 이제 VCall 동반자 전화 서비스 자원봉사자 입니다. 성실히 고객/간병인의 말을 듣고 대화에 임하기 바랍니다. 실전을 위해 책자로 (도움 자료 - 자원봉사자 - VCall 자원봉사자 환영 핸드북) 교육을 간소화했으니 다운로드 하여 도움이 필요할떼 참고하기 바랍니다.</Typography>


                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="training-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '18px' }}>Volunteer Training - 의무 교육</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>

                            <Typography sx={{ mt: 2, mb: 2 }}>
                                영국 알츠하이머 협회와 함께 아래 교육 모듈을 개발했습니다. 완전히 완료하시기 바랍니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                일부 세부 사항은 VCall에 적용되지 않습니다 (e.g., 전화번호, 간병인 보호 프로토콜). 한국어 pdf 를 참조하여  VCall 관련 정보를 학습하세요.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                모든 의무 교육을 끝내고 vcallhelp@gmail.com 에 아래 서식 이메일을 보내시기 바랍니다:
                                <li>의무 교육 끝</li>
                                <li>이름</li>
                                <li>생년월일</li>
                                <li>담당 매니저와 미팅 가능 날짜와 시간 (한국 시간)</li>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                VCall & 알츠하이머 협회 모듈 1: 안녕하세요, 환영합니다, 감사합니다
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://rise.articulate.com/share/aktdzxVM3Ae1pqaDp1PFYVqi6LG3GFoH"}>
                                    영어 - 모듈 1
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1dfLonN4UflAxUnKJw1dRMEyrflVKyi16/view?usp=drive_link"}>
                                    한국어 pdf - 모듈 1
                                </Button>
                            </Typography>

                            <Typography sx={{ mt: 5, mb: 1 }}>
                                VCall & 알츠하이머 협회 모듈 2: 핵심 정보
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://rise.articulate.com/share/8HVDhJbuzbLY_WJcLoTXLiGhFaN26RmH"}>
                                    영어 - 모듈 2
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1Vyg0ycNa9pF8LqjKLshjlvyKFz_Tzmfv/view?usp=sharing "}>
                                    한국어 pdf - 모듈 2 (연결된 앱 - Lumin pdf 을 사용하세요)
                                </Button>
                            </Typography>

                            <Typography sx={{ mt: 5, mb: 1 }}>
                                VCall & 알츠하이머 협회 모듈 3: 자원봉사자 관계
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://rise.articulate.com/share/sZ53EQD_2-y9b_JIzUaeRzo1OrYDzast"}>
                                    영어 - 모듈 3
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1UOu3a2jNfGr1YmndY3X7qaFziT5C6pAL/view?usp=sharing"}>
                                    한국어 pdf - 모듈 3
                                </Button>
                            </Typography>

                            <Typography sx={{ mt: 5, mb: 1 }}>
                                VCall & 알츠하이머 협회 자원봉사자 안전보호 교육
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://rise.articulate.com/share/OiQitLvnKbb8YqHQDXbBCAV_WZg2i4KW"}>
                                    영어 - 자원봉사자 안전보호 교육
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1dj5TGHQQVTafXHX1kZxRiuXhoF-cN3dX/view?usp=sharing "}>
                                    한국어 pdf - 자원봉사자 안전보호 교육 (Lumin pdf 익스텐션을 사용하세요)
                                </Button>
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="training-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '18px' }}>Safeguarding & Follow up requests - 안전보호 & 지원요청</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>

                            <Typography sx={{ mt: 2, mb: 4 }}>
                                *안전 보호/지원 요청 이 있으면 아래 항목을 "예"라고 답하세요.
                                안전 보호 문제와 지원 요청의 차이:
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 3 }}>
                                안전 보호 문제 (학대, 즉각적 피해):
                                <li>예: 폭력, 성폭행, 자살, 충동적인 생각 등</li>
                                <li>vcallhelp@gmail.com 에 즉시 아래 양식의 이메일 보내기:</li>
                                <li>“응급 - 봉사자 이름 - 고객 이름”</li>
                                <li>최대한 자세히 모든 정보와 왜 안전 보호 문제 라고 생각하는지 적어주세요.</li>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 5 }}>
                                <li>www.vcall-korea.com 의 프로필 페이지의 “온라인 통화 기록 양식”을 완료하고 “후속 조치 요청” 양식을 아래 양식처럼 써주세요:</li>
                                <li>응급 - 안전보호</li>
                                <li>이유:</li>
                                <li>세부사항</li>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 4 }}>
                                고객/간병인 지원 요청 (보호 문제는 아니지만 지원이 필요할떼):
                                <li>예: 재정, 움직임 도움, 장보기 등</li>
                                <li>www.vcall-korea.com 의 프로필 페이지의 “온라인 통화 기록 양식”을 완료하고 “후속 조치 요청” 양식을 지원 요청 사항, 이유, 그리고 원하는것을 자세히 써서 제출하세요.</li>
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="training-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '18px' }}>How to use VCall - 자원봉사자 VCall 사용법</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>
                            <Typography>
                                *자원봉사자는 사교적 대화와 한정적인 도움을 드릴수있습니다. 도움/질문이 있으시다면 봉사자에게 서스럼없이 예기하시기 바랍니다. VCall 은 구글 이메일로 고객님의 질문에 대한 답을 보내드리겠습니다.
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                고객과의 첫 미팅:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>되는시간 계정의 “가능한 시간” 탭 에 자신이 매주 통화 할수 있는 시간을 세팅 해 놓으세요. </li>
                                <li>담당 관계자에게 고객과 매칭이 되었나는 이메일을 받은후 되는시간 과 구글 계정의 받은 편지함을 상시로 확인하세요.</li>
                                <li>되는시간에 예약된 시간을 확인하고 5분전 미팅을 시작하세요 (아래 섹션).</li>
                                <ol stype={{ listStyle: 'lower-latin' }}>
                                    <li>카메라를 켜는게 불편하시면 양해를 구하고 안 켜셔도 됩니다. </li>
                                </ol>
                                <li>첫 미팅이니 VCall 자원봉사자 환영 핸드북의 ‘대화카드 리소스’ (pp. 6-7)를 활용하세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1YUTkgJiGCJP-b366Pc_VaIoDESEsUBb8/view?usp=sharing"}>
                                        VCall 자원봉사자 환영 핸드북
                                    </Button>
                                </Typography>
                                <li>미팅 이 끝나기전 오늘 전화한 시간이 좋은 시간인지 확인하고 스케줄을 조율하세요.</li>
                                <li>미팅을 정중히 끝낸후 온라인 통화 기록 양식을 작성하세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"/login"}>
                                        온라인 통화 기록 양식
                                    </Button>
                                </Typography>
                            </ol>
                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 시작법:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>홈페이지 우측 상단의 동그란 버튼을 눌러 구글 계정으로 로그인 하세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"/login"}>
                                        로그인
                                    </Button>
                                </Typography>
                                <ol stype={{ listStyle: 'lower-latin' }}>
                                    <li>꼭 자원봉사자 지원을 했던 같은 계정으로 로그인 하세요.</li>
                                    <li>로그인 방법을 까먹으셨다면 "Resources - 도움 자료" 의 “자원봉사자 지원 법”을 읽으세요. 도움이 필요하시다면 vcallhelp@gmail.com 으로 이메일을 보내세요.</li>
                                </ol>
                                <li>로그인후 동그란 버튼을 다시 누르세요. 그것이 프로필 페이지 입니다. 구글 미츠, 되는시간 링크 와 온라인 통화기록 양식이 보일것 입니다.</li>
                                <li>되는시간으로 미팅 시간을 확인하세요.</li>
                                <li>약속된 시간 5분전 구글 미츠 링크를 열어 미팅을 시작하세요.</li>
                                <li>로비에서 누군가가 기다리고 있다면 들여보내주세요.</li>
                                <li>*일찍 끝내야 될거 같으면 미리 양해를 구하세요.</li>
                            </ol>
                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 중:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>교육에서 배웠던 것을 기억하며 사교적인 대화를 나누세요.</li>
                                <li>무엇에 대해 예기할까 고민이면 VCall 자원봉사자 환영 핸드북의 ‘대화카드 리소스’를 활용하세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1YUTkgJiGCJP-b366Pc_VaIoDESEsUBb8/view?usp=sharing"}>
                                        VCall 자원봉사자 환영 핸드북
                                    </Button>
                                </Typography>
                                <li>기억하세요 - 말하는 것보다 듣는것이 더 중요합니다.</li>
                                <li>사적 정보 (예: 전화번호, 주소)는 발설하지 마세요. 대략 어디 사는지는 예기해도 됩니다.</li>
                            </ol>

                            <Typography sx={{ mt: 3, mb: 1 }}>
                                미팅 끝내기:
                            </Typography>
                            <ol style={{ listStyle: 'decimal' }}>
                                <li>고객/간병인 과 “되는시간” 링크를 이용하여 다음 미팅시간을 잡으세요. 꼭 미팅 확정후 끝내기 바랍니다.</li>
                                <ol stype={{ listStyle: 'lower-latin' }}>
                                    <li>간병인이 도움이 필요하다면 프로필 페이지에서 자신의 “되는시간” 링크를 복사한후 구글 미츠의 챚 도구를 이용해 간병인에게 보내세요.</li>
                                </ol>
                                <li>빨간 버튼을 눌러 미팅을 끝네세요.</li>
                                <li>프로필로 돌아가 “온라인 통화 기록 양식”을 완료후 제출해주세요.</li>
                                <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"/login"}>
                                        온라인 통화 기록 양식
                                    </Button>
                                </Typography>
                                <li>지원 요청 이나 안전 보호 문제가 있는거 같으면 “후속 조치 요청” 문항을 최대한 자세히 써주시기 바랍니다 (안전 보호 문제와 지원 요청의 차이 문서에서 더 자세히 알아보세요).</li>
                                <li>문제나 문의가 있다면 vcallhelp@gmail.com 에 이메일을 보내세요.</li>
                            </ol>
                            <Typography sx={{ mt: 3, mb: 1 }}>추가 정보:</Typography>
                            <Typography sx={{ mb: 1 }}>
                                <li>매 12주 담당 매니저가 상태 확인 이메일을 보낼거니 답 해주시기 바랍니다.</li>
                                <li>온라인 통화 기록은 모두 데이터 베이스에 저장되니 다시 보고 싶으시다면 이메일을 주세요.</li>
                            </Typography>
                            <Typography sx={{ mt: 3, mb: 1 }}>
                                아래 플로우차트 (flowchart)는 VCall 사용법의 요약입니다.
                            </Typography>
                            < img
                                style={{ marginTop: 0 }}
                                alt={"loading"}
                                width={"100%"}
                                src='./Flowchart.jpg' ></img >



                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volhandbook"
                            id="volhandbook"
                        >
                            <Typography sx={{ fontSize: '18px' }}>VCall Volunteer Welcome Handbook - 자원봉사자 환영 핸드북</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                첫 미팅전 읽어보시기 바랍니다.
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://drive.google.com/file/d/1YUTkgJiGCJP-b366Pc_VaIoDESEsUBb8/view?usp=sharing"}>
                                    VCall 자원봉사자 환영 핸드북
                                </Button>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>
                </Box>


                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 치매 간병 정보
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography>
                        전문적인 치매 간병 정보가 필요하시다면 vcallhelp@gmail.com 에 이메일을 보내시고 아래 자료를 참고해보시기 바랍니다.
                        *자원봉사자에게 추가 정보 지원 요청을 하시면 VCall 에서 구글 이메일로 연락 드리겠습니다.
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volhandbook"
                            id="volhandbook"
                        >
                            <Typography sx={{ fontSize: '18px' }}> 알츠하이머 협회 (영국) </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>

                            <Typography>
                                아래 정보는 영국 특정 정보이니 이를 염두하시고 참고하시기 바랍니다. 질문이 있으시면 vcallhelp@gmail.com 으로 이메일을 보내주시기 바랍니다.
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    홈페이지 (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/symptoms-and-diagnosis/worried-memory-problems-or-dementia?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    기억력 문제가 걱정되세요? (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/five-things-you-should-know-about-dementia?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    치매에 대해 알아야 할 다섯 가지 (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/types-dementia?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    치매의 종류 (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/symptoms-and-diagnosis?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    증상 및 진단 (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/treatments?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    치료 (한문)
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www-alzheimers-org-uk.translate.goog/about-dementia/dementia-risk-factors-and-prevention?_x_tr_sl=auto&_x_tr_tl=ko&_x_tr_hl=en-US&_x_tr_pto=wapp"}>
                                    치매 위험요인과 예방 (한문)
                                </Button>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>

                </Box>


                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volhandbook"
                            id="volhandbook"
                        >
                            <Typography sx={{ fontSize: '18px' }}> 중앙치매센터 </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>


                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/main/main.aspx"}>
                                    홈페이지
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/support/callcenter.aspx"}>
                                    치매 상담 콜 센터
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/support/jajo_group.aspx"}>
                                    치매 가족 모임
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/info/diction_list1.aspx?gubun=0101"}>
                                    치매사전
                                </Button>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px",
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volhandbook"
                            id="volhandbook"
                        >
                            <Typography sx={{ fontSize: '18px' }}> 가족 간병인 연합 (미주) </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>


                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.caregiver.org/korean/"}>
                                    홈페이지
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.caregiver.org/ko/resource/%EA%B1%B4%EA%B0%95-%EB%8F%8C%EB%B4%84%EA%B3%BC-%EC%9A%B0%EC%9A%B8%EC%A6%9D-caregiving-and-depression-korean/?via=korean"}>
                                    간병과 우울증
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.caregiver.org/ko/resource/%EA%B1%B4%EA%B0%95-%EB%8F%8C%EB%B4%84%EC%9D%B4%EB%A5%BC-%EC%9C%84%ED%95%9C-%EC%B9%98%EB%A7%A4-%ED%96%89%EB%8F%99-%EA%B0%80%EC%9D%B4%EB%93%9C-caregivers-guide-understanding-dementia-behaviors-korean/?via=korean"}>
                                    건강 돌봄이를 위한 치매 행동 가이드
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.caregiver.org/ko/resource/%EC%95%8C%EC%B8%A0%ED%95%98%EC%9D%B4%EB%A8%B8%EB%B3%91%EA%B3%BC-%EA%B0%84%ED%98%B8-alzheimers-disease-caregiving-korean/?via=korean"}>
                                    알츠하이머병과 간호
                                </Button>
                            </Typography>

                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.caregiver.org/ko/resource/%EC%B9%98%EB%A7%A4-%EA%B0%84%EB%B3%91-%EA%B7%B8%EB%A6%AC%EA%B3%A0-%EC%A2%8C%EC%A0%88%EA%B0%90-%EB%8B%A4%EC%8A%A4%EB%A6%AC%EA%B8%B0-dementia-caregiving-and-controlling-frustration/?via=korean"}>
                                    치매, 간병 그리고 좌절감 다스리기
                                </Button>
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                </Box>


                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Accordion sx={{
                        width: "100%", backgroundColor: "#edf1f7",
                        boxShadow: "rgba(45,55,75,.12) 1px 1px 1px 1px"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="volhandbook"
                            id="volhandbook"
                        >
                            <Typography sx={{ fontSize: '18px' }}> 대한치매협회 </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 5, pb: 10 }}>
                            <Typography>
                                한국 안에서의 치매 정보는 대한치매협회 사이트를 이용하시는 것을 권고 합니다.
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/"}>
                                    홈페이지
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/main/sub.html?pageCode=10003"}>
                                    대한치매협회 회원 가입
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/main/sub.html?pageCode=79"}>
                                    치매전문자료
                                </Button>
                            </Typography>
                            <Typography sx={{ pl: 3, pt: 2, pr: 3, pb: 2 }} color="secondary.main">
                                <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/main/sub.html?mstrCode=4"}>
                                    치매네트워크/모임
                                </Button>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>


        </div>
    );
}
export default Resources;