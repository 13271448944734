import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";

import { db } from "../services/firebase";

function Row(props) {
  const { user } = props;
  const [open, setOpen] = React.useState(false);

  const formattedRegisteredDate = user.registeredDate
    ? new Date(user.registeredDate.seconds * 1000).toLocaleString()
    : "";

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.name}
        </TableCell>
        <TableCell align="right">{user.email}</TableCell>
        <TableCell align="right">{user.role}</TableCell>
        <TableCell align="right">{user.birthDate}</TableCell>
        <TableCell align="right">{formattedRegisteredDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="user-details">
                <TableBody>
                  <TableRow>
                    <TableCell>Client Why</TableCell>
                    <TableCell>{user.clientWhy}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conviction</TableCell>
                    <TableCell>{user.conviction}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Post Code</TableCell>
                    <TableCell>{user.postCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Preference</TableCell>
                    <TableCell>{user.preference}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Situation</TableCell>
                    <TableCell>{user.situation}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>UID</TableCell>
                    <TableCell>{user.uid}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Volunteer Skill</TableCell>
                    <TableCell>{user.volunteerSkill}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Why</TableCell>
                    <TableCell>{user.why}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Google Link</TableCell>
                    <TableCell>{user.googlelink}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>되는시간 Link</TableCell>
                    <TableCell>{user.calendarlink}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const userData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userData);
      } catch (error) {
        console.error(error);
        console.log("An error occurred while fetching user data.");
      }
    };

    fetchUsers();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>User</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Birth Date</TableCell>
            <TableCell align="right">Registered Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <Row key={user.id} user={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
