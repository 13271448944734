import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import InfoSection from '../components/InfoSection';
import { auth, db, signInWithGoogle } from "../services/firebase";

import "./Login.css";

function Login() {

    const [user, loading, error] = useAuthState(auth);
    const [isUserRegistered, setIsUserRegistered] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    //The Questions:
    const [userRole, setUserRole] = useState('');
    const [userGender, setUserGender] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userPost, setUserPost] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userBirth, setUserBirth] = useState('');
    const [userWhy, setUserWhy] = useState('');
    const [userCWhy, setUserCWhy] = useState('');
    const [userVSkill, setUserVSkill] = useState('');
    const [userGoogle, setUserGoogle] = useState('');
    const [userCalendar, setUserCalendar] = useState('');
    const [userPref, setUserPref] = useState('');
    const [userCon, setUserCon] = useState('');
    const [userSit, setUserSit] = useState('');


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmissionError, setIsSubmissionError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const navigate = useNavigate();
    // useEffect(() => {
    //     if (loading) {
    //         // maybe trigger a loading screen
    //         return;
    //     }
    //     if (user && isUserRegistered) navigate("/dashboard")

    // }, [user, loading, isUserRegistered]);

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            if (!user) {
                return
            }
            const querySnapshot = await getDocs(collection(db, "users"));
            const users = querySnapshot.docs;
            const userDoc = users.find((doc) => doc.data().email === user.email);
            if (userDoc && userDoc.get("role") === "admin") {
                setIsUserRegistered(true)
                navigate("/dashboard")
            } else if (userDoc && userDoc.get("role") !== "admin" && userDoc.get("role")) {
                setIsUserRegistered(true)
                navigate("/profile")
            } else {
                // logged in with google, but not registered with Q's
                setIsUserRegistered(false)
            }


            setIsLoading(false)

        })();
    }, [user, loading, isUserRegistered])



    const handleFormSubmit = async () => {
        if (!userRole || !userGender || !userAddress || !userPost || !userPhoneNumber || !userBirth || !userPref || !userCon || !userSit) {
            alert("'자원봉사자가 되고싶은 이유' / '고객이 되고싶은 이유' / '지원 직무와 관련된 기술 및 경험' / '구글 미트' / '되는시간' 문항들을 제외하고 모든 항목을 작성하십시오")
            return
        }


        setIsSubmitting(true);
        setIsSubmissionError(false);
        try {
            const querySnapshot = await getDocs(collection(db, "users"));
            const users = querySnapshot.docs;
            const userDoc = users.find((doc) => doc.data().email === user.email);

            if (userDoc) {
                const userRef = doc(db, "users", userDoc.id);

                await updateDoc(userRef, {
                    role: userRole, registeredDate: new Date(), gender: userGender,
                    address: userAddress, postCode: userPost, phoneNumber: userPhoneNumber, birthDate: userBirth,
                    why: userWhy, clientWhy: userCWhy, volunteerSkill: userVSkill, preference: userPref,
                    conviction: userCon, situation: userSit, googlelink: userGoogle, calendarlink: userCalendar,
                    matchedName: ""
                });

            } else {
                throw new Error("User document not found");
            }

            setIsUserRegistered(true);
        } catch (error) {
            console.error("Error adding user role to Firebase: ", error);
            setIsSubmissionError(true);
        } finally {
            setIsSubmitting(false);
        }
    };






    if (!user) {
        return (
            <Box sx={{ minHeight: "70vh", display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", pb: 10 }} data-aos="fade-up">

                <Box sx={{
                    pb: 5,
                    width: isMobile ? "75%" : "40%",
                }}>
                    <InfoSection title={"VCall 회원가입"} subtitle={"VCall 사용을 위해 Google 계정이 필요합니다. 계정이 없다면 아래에서 계정을 만들어주세요."} height={"270px"} />
                </Box>
                <Box sx={{
                    width: isMobile ? "75%" : "40%",
                }}>

                    <a href="https://support.google.com/accounts/answer/27441?hl=ko" target="_blank">

                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            startIcon={<img src="./search.png" alt="Google Icon" style={{ width: "24px" }} />}
                            fullWidth
                            style={{ marginTop: '16px' }}
                        >
                            Google 계정 만들기
                        </Button>
                    </a>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        startIcon={<img src="./search.png" alt="Google Icon" style={{ width: "24px" }} />}
                        onClick={signInWithGoogle}
                        fullWidth
                        style={{ marginTop: '16px' }}
                    >
                        Google 계정으로 로그인하기
                    </Button>
                </Box>
            </Box>
        );
    } else if (!isUserRegistered) {

        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSubmitting || isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Box sx={{ pt: 10, pb: 5 }} hidden={isLoading}>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: "bold" }} color={'primary.main'}>
                        안녕하세요, {user.displayName}님, 등록을 완료하려면 양식을 계속 작성하십시오.
                    </Typography>

                    {/* 
                   Are you applying for volunteer/client?
                   Personal details: (X)
                        Gender (X)
                        Address 1, Address 2 (X)
                        Town
                        County
                        Country
                        Postcode
                        Mobile Telephone No: (X)
                        Are you 18 years of age or over?
                        What is your birth date? (X)
                    U18 Applications:
                        Are you under 16 or not? (If under 16, not allowed)
                        Parental detail:
                            Parent/Guardian Full Name
                            Parent/Guardian Contact Number
                            Parent/Guardian Email Address
                            Your Date of Birth
                    About You:
                        *Please tell us why you would like to become a volunteer with Alzheimer’s Society and what you hope to get out of the role:
                        *Please tell us about the skills and experience you have that are relevant to the role you are applying for (max 200 words)
                        Any preferences in who you want to talk with?
                        Do you have any unspent criminal convictions?
                            IF yes --> box to explain why
                        If you feel comfortable to do so, please tell us about your situation relating to dementia - tick all that apply:
                            I am a person with dementia
                            I am caring for a person with dementia
                            I am a former carer
                            I am none of the above
 
                */}
                    {/* Use this for the questions: */}
                    {/* <TextField
  id="outlined-controlled"
  label="Controlled"
  value={name}
  onChange={(event) => {
    setUserGender(event.target.value);

}}
/> */}


                    {/* Role */}
                    <Box sx={{ minWidth: 120, mt: 5, mb: 5 }}>
                        <InputLabel id="demo-simple-select-label">지원역할 을 고르십시오*</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userRole}
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={(event) => {
                                    setUserRole(event.target.value);

                                }}
                            >
                                <MenuItem value={"volunteer"}>자원봉사자</MenuItem>
                                <MenuItem value={"client"}>고객</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    {/* Gender */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">성별*</InputLabel>

                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userGender}
                                inputProps={{ 'aria-label': 'Without label' }}

                                onChange={(event) => {
                                    setUserGender(event.target.value);

                                }}
                            >
                                <MenuItem value={"male"}>남</MenuItem>
                                <MenuItem value={"female"}>여</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Address */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>

                        <InputLabel id="demo-simple-select-label">주소 / 광역시도-시군구-읍면동-숫자-(건물명-호수)*</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                placeholder='주소'
                                value={userAddress}
                                onChange={(event) => {
                                    setUserAddress(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Postcode */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">우편번호*</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                placeholder='우편번호'
                                value={userPost}
                                onChange={(event) => {
                                    setUserPost(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Phone Number */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">전화번호*</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                placeholder='전화번호'
                                value={userPhoneNumber}
                                onChange={(event) => {
                                    setUserPhoneNumber(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Birth Date */}
                    <Box sx={{ minWidth: 120, mt: 5, mb: 5 }}>
                        <InputLabel id="demo-simple-select-label">생년-월-일*</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                placeholder='생년월일'
                                value={userBirth}
                                onChange={(event) => {
                                    setUserBirth(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Why you */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">자원봉사자인 경우</InputLabel>
                        <Typography variant='subtitle1' sx={{ mb: 1, mt: 1 }}>
                            VCall 자원봉사자가 되고 싶은 이유와 전화봉사자 로서 얻고 싶은 것을 말씀해 주십시오.
                        </Typography>
                        <FormControl fullWidth>
                            <TextField multiline
                                minRows={3}
                                id="outlined-controlled"
                                value={userWhy}
                                onChange={(event) => {
                                    setUserWhy(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Why you */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">고객인 경우</InputLabel>
                        <Typography variant='subtitle1' sx={{ mb: 1, mt: 1 }}>
                            VCall 고객이 되고 싶은 이유와 전화봉사 서비스 로 얻고 싶은 것을 말씀해 주십시오.
                        </Typography>
                        <FormControl fullWidth>
                            <TextField multiline
                                id="outlined-controlled"
                                minRows={3}
                                value={userCWhy}
                                onChange={(event) => {
                                    setUserCWhy(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Your skills - Volunteer */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">자원봉사자인 경우</InputLabel>
                        <Typography variant='subtitle1' sx={{ mb: 1, mt: 1 }}>
                            지원 직무와 관련된 기술 및 경험에 대해 알려주세요
                        </Typography>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                value={userVSkill}
                                multiline
                                minRows={3}
                                onChange={(event) => {
                                    setUserVSkill(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* Google Meeting Link - Volunteer */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">자원봉사자인 경우</InputLabel>

                        <Button sx={{ mt: 1, mb: 1 }} target="_blank" rel="noopener noreferrer" variant='outlined' href="https://apps.google.com/intl/ko/intl/ko_ALL/meet/"> 구글 미트 </Button>
                        <Typography variant='subtitle1' sx={{ mb: 1, mt: 1 }}>
                            위 왭페이지를 접속하세요. 로그인 후 "새 회의" 파란 버튼을 누르세요. "나중에 진행할 회의 만들기" 를 누르세요. 만들어진 링크를 아래 적어주세요. 이 미팅 링크는 향후 전화봉사때 쓰일 것입니다.
                        </Typography>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                value={userGoogle}
                                multiline
                                minRows={3}
                                onChange={(event) => {
                                    setUserGoogle(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>

                    {/* 되는시간 링크 - Volunteer */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">자원봉사자인 경우</InputLabel>
                        <Button sx={{ mt: 1, mb: 1 }} target="_blank" rel="noopener noreferrer" variant='outlined' href="https://whattime.co.kr/"> 되는시간</Button>
                        <Typography variant='subtitle1' sx={{ mb: 1, mt: 1 }}>
                            &quot;되는시간&quot; 은 VCall 이 쓰는 일정 관리 캘린더 도구입니다. 자원봉사자가 &quot;되는시간&quot; 계정을 만들어 봉사할수있는 시간들을 적어놓으면 간병인과
                            일정을 조울할수 있는 시스템으로, 계정을 만들어 개인 링크를 밑에 붙여넣기 바랍니다. 도움이 필요하시면 이 웹사이트의 &quot;자원&quot; 을 누른 이후
                            &quot;봉사자를 위한&quot; 아래에서 &quot;되는시간 도움서&quot;를 찾아보시기 바랍니다.
                        </Typography>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-controlled"
                                value={userCalendar}
                                multiline
                                minRows={4}
                                onChange={(event) => {
                                    setUserCalendar(event.target.value);

                                }}
                            />
                        </FormControl>
                    </Box>




                    {/* Preference */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">선호하는 대화 상대가 있습니까?*</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userPref}
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={(event) => {
                                    setUserPref(event.target.value);

                                }}
                            >
                                <MenuItem value={"male"}>남</MenuItem>
                                <MenuItem value={"female"}>여</MenuItem>
                                <MenuItem value={"none"}>무</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Criminal Convictions */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">집행되지 않은 전과*</InputLabel>
                        <Typography id="demo-simple-select-label" variant='subtitle1' sx={{ mb: 1, mt: 1 }}>아직 집행되지 않은 전과가 있으신가요?</Typography>

                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userCon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={(event) => {
                                    setUserCon(event.target.value);

                                }}
                            >
                                <MenuItem value={"yes"}>네</MenuItem>
                                <MenuItem value={"no"}>아니요</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Current situation */}
                    <Box sx={{ minWidth: 120, mt: 5 }}>
                        <InputLabel id="demo-simple-select-label">치매와 관련된 상황*</InputLabel>

                        <Typography id="demo-simple-select-label" variant='subtitle1' sx={{ mb: 1, mt: 1 }}>치매와 관련된 귀하의 상황을 알려주시기 바랍니다.</Typography>

                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userSit}
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={(event) => {
                                    setUserSit(event.target.value);

                                }}
                            >
                                <MenuItem value={"dementia patient"}>치매 환자입니다</MenuItem>
                                <MenuItem value={"dementia carer"}>치매 간병인입니다</MenuItem>
                                <MenuItem value={"ex dementia carer"}>전 치매 간병인입니다</MenuItem>
                                <MenuItem value={"none of the above"}>위의 항목 중 어느 것도 해당되지 않습니다.</MenuItem>

                            </Select>
                        </FormControl>
                    </Box>






                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5, mb: 10 }}>
                        <Button onClick={handleFormSubmit} variant='contained' size='large'>제출하기</Button>
                    </Box>
                </Box>
            </>
        )

    }
}
export default Login;