import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import CollapsibleTable from '../components/CollapsibleTable';
import CollapsibleTableClientLogs from '../components/CollapsibleTableClientLogs';
import CollapsibleTableVolunteerLogs from '../components/CollapsibleTableVolunteerLogs';
import { auth, db } from "../services/firebase";

import "./Dashboard.css";

function Dashboard() {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const fetchUserName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            console.log("Query:", q);

            const querySnapshot = await getDocs(q);
            console.log("Query snapshot:", querySnapshot);

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                const data = doc.data();
                console.log("User data:", data);
                setName(data.name);
                data.role !== "admin" && navigate("/");
            } else {
                console.log("No matching documents found.");
            }
        } catch (err) {
            console.error(err);
            console.log("An error occurred while fetching user data in Dashboard.js");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) {
            console.log("no user")
            navigate("/")
        };
        (async () => {
            setIsLoading(true)
            await fetchUserName()
            setIsLoading(false)

        })();


    }, [user, loading]);

    if (!user || loading || isLoading) return (

        <Box sx={{ height: "100vh" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                <Box>

                    <Typography variant="h5" sx={{ mt: 20 }}>Validating User Role...</Typography>
                </Box>
                <Box sx={{ mt: 20 }}>
                    <CircularProgress />
                </Box>
            </Box>
        </Box>



    )

    return (
        <Box sx={{ pb: 5 }}>
            <Box sx={{ display: "flex", flexDirection: "column", p: 5 }}>
                <Typography variant="overline" sx={{ mt: 2, mb: 2, color: "primary", fontSize: 22 }} >Users</Typography>
                <CollapsibleTable />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", p: 5 }}>
                <Typography variant="overline" sx={{ mt: 2, mb: 2, color: "primary", fontSize: 22 }} >Client Logs</Typography>
                <CollapsibleTableClientLogs />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", p: 5 }}>
                <Typography variant="overline" sx={{ mt: 2, mb: 2, color: "primary", fontSize: 22 }} >Volunteer Logs</Typography>
                <CollapsibleTableVolunteerLogs />
            </Box>
        </Box>
    );
}
export default Dashboard;