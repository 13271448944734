import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WarningIcon from '@mui/icons-material/Warning';
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";

import { db } from "../services/firebase";

function Row(props) {
    const { user } = props;
    const [open, setOpen] = React.useState(false);
    function isOlderThanTwelveWeeks(d) {

        const today = new Date().toLocaleString()
        const diff = Math.abs(new Date(today) - new Date(d));
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        return days > 84
    }

    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell style={{ display: "flex", alignContent: "center" }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >

                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

                    </IconButton>
                    {user.followuprequest === "yes" || isOlderThanTwelveWeeks(new Date(user.latestsubmissionDate.seconds * 1000).toLocaleString()) ?

                        <WarningIcon style={{ color: "red", mt: 1 }} /> : null
                    }
                </TableCell>
                <TableCell align="right">{user.volunteerName}</TableCell>
                <TableCell align="right">{new Date(user.latestsubmissionDate.seconds * 1000).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="user-details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Client Name</TableCell>
                                        <TableCell>{user.clientName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Follow-up Request</TableCell>
                                        <TableCell>{user.followuprequest}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Log</TableCell>
                                        <TableCell>{user.log}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Volunteer Email</TableCell>
                                        <TableCell>{user.volunteerEmail}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTableVolunteerLogs() {
    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "volunteerlogs"));
                const userData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUsers(userData);
            } catch (error) {
                console.error(error);
                console.log("An error occurred while fetching user data.");
            }
        };

        fetchUsers();
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="right">Volunteer Name</TableCell>
                        <TableCell align="right">Latest Submission Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <Row key={user.id} user={user} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
