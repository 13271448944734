import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import ListItemButton from '@mui/material/ListItemButton';
import Typography from "@mui/material/Typography";
import * as React from 'react';
import { Link } from "react-router-dom";

function Footer() {

    const navItems = ['홈', '회사 소개', '도움 자료', '계시판'];
    const navItemsPath = ['', 'aboutus', 'resources', 'forum'];
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            backgroundColor={"secondary"}
            sx={{ backgroundColor: "secondary", paddingBottom: "5vh" }}
        >

            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>

                <img width={80} height={80}
                    className="img-animation"
                    alt={"loading"}
                    src='./logo.png'></img >

                <Typography variant={"subtitle1"} color={"#666"} >vcallhelp@gmail.com</Typography>
            </Box>
            <Grid container direction="row" justifyContent={"center"}>
                {navItems.map((item, idx) => (
                    <Link key={idx.toString()} to={navItemsPath[idx]} style={{ textDecoration: 'none' }}>
                        <ListItemButton><Typography variant={"overline"} color={"secondary.main"}>{item}</Typography>
                        </ListItemButton>
                    </Link>

                ))}
            </Grid>

        </Grid >
    );
}

export default Footer;
