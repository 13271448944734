import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

export default function ClickableInfoSection({ color = '#edf1f7', image, title, description1, description2, isExternal, link, children }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleOnClick = () => {
        if (isExternal) {
            window.open(link, "_blank");
        } else {
            navigate(link);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                cursor: 'pointer',
                borderRadius: 8,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.12)",
                backgroundColor: '#edf1f7',
                border: `2px solid ${color}`,
                "&:hover": {
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    backgroundColor: 'rgba(166, 187, 214, 0.5)',
                    transition: "700ms",
                },
                p: 3, // Add padding to all sides of the box
                pt: 5,
                width: '100%',
                height: 185, // Fixed height of the box
            }}
            onClick={handleOnClick}
        >
            <Box sx={{
                display: "flex", justifyContent: "center", height: 60,
            }}>
                {children}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Typography variant="body2" gutterBottom color="#223" sx={{ fontWeight: "600" }} >
                    {title}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Typography variant="subtitle2" gutterBottom color="primary.main">
                    {description1}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="subtitle1" gutterBottom color="primary.main">
                    {description2}
                </Typography>
            </Box>
        </Box>
    );
}
