import WarningIcon from '@mui/icons-material/Warning';
import { Backdrop, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth, db } from "../services/firebase";

const FeedbackFormVolunteer = ({ userData }) => {
    const navigate = useNavigate();


    const [userVolunteerName, setUserVolunteerName] = useState('');
    const [userVolunteerEmail, setUserVolunteerEmail] = useState('');
    const [userClientName, setUserClientName] = useState('');
    const [userLog, setUserLog] = useState('');
    const [userSafeguarding, setUserSafeguarding] = useState('');
    const [userSgexplanation, setUserSgexplanation] = useState('');

    const [user, loading, error] = useAuthState(auth);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmissionError, setIsSubmissionError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleFormSubmit = async () => {

        if (!userVolunteerName || !userVolunteerEmail || !userClientName || !userLog || !userSafeguarding) {
            alert("별표가 있는 모든 항목을 작성하십시오")
            return
        }

        setIsLoading(true);
        setIsSubmitting(true);
        setIsSubmissionError(false);
        try {
            const dbRef = collection(db, "volunteerlogs");


            const res = await addDoc(dbRef, {
                volunteerName: userVolunteerName, latestsubmissionDate: new Date(), volunteerEmail: userVolunteerEmail,
                clientName: userClientName, log: userLog, followuprequest: userSafeguarding, sgexplanation: userSgexplanation
            });

            console.log(res)

        } catch (error) {
            console.error("Error adding logs to Firebase: ", error);
            setIsSubmissionError(true);

        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
            alert("등록이 완료되었습니다. 감사합니다.")
            navigate("/");
        }
    };

    return (
        <Box sx={{ minWidth: 120, mt: 5, mb: 5 }}>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSubmitting || isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ pb: 5 }} hidden={isLoading}>



                <Box
                    sx={{
                        border: "1px solid #f5f7fa",
                        borderRadius: "10px",
                        height: "100%",
                        mb: 5,
                        p: 3,
                        pt: 5,
                        pb: 5,
                        backgroundColor: 'rgb(245, 247, 250)',
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",


                    }}
                    color="secondary.main"
                >

                    {
                        userData?.matchedName?.length === 0 ?
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                <WarningIcon style={{ color: "orange", mt: 3, mr: 2 }} />
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    매칭된 고객이 없습니다. 매칭이 완료되면 고객의 이름이 표시됩니다.
                                </Typography>
                            </Box>
                            :
                            <>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                    매칭된 고객: <span style={{ fontSize: "22px", color: "grey", fontWeight: "bold" }}>{userData.matchedName}</span>
                                </Typography>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                    미팅 링크: <a style={{ fontSize: "14px", color: "grey" }} href={userData.googlelink} target='_blank'>{userData.googlelink}</a>
                                </Typography>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                    켈린더 링크: <a style={{ fontSize: "14px", color: "grey" }} href={userData.calendarlink} target='_blank'>{userData.calendarlink}</a>
                                </Typography>
                            </>




                    }
                </Box>



                <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                    안녕하세요, {user.displayName}님, 미팅 종료후 아래 온라인 통화 기록 양식을 제출해주세요.
                </Typography>






                {/* Volunteer Name */}
                <Box sx={{ minWidth: 120, mt: 5 }}>


                    <InputLabel id="volunteername">자원봉사자 이름*</InputLabel>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-controlled"
                            placeholder='자원봉사자 이름*'
                            value={userVolunteerName}
                            onChange={(event) => {
                                setUserVolunteerName(event.target.value);

                            }}
                        />
                    </FormControl>
                </Box>

                {/* Volunteer Email */}
                <Box sx={{ minWidth: 120, mt: 5 }}>


                    <InputLabel id="volunteeremail">자원봉사자 이메일*</InputLabel>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-controlled"
                            placeholder='자원봉사자 이메일*'
                            value={userVolunteerEmail}
                            onChange={(event) => {
                                setUserVolunteerEmail(event.target.value);

                            }}
                        />
                    </FormControl>
                </Box>

                {/* Client Name */}
                <Box sx={{ minWidth: 120, mt: 5 }}>


                    <InputLabel id="clientname">간병인 이름*</InputLabel>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-controlled"
                            placeholder='간병인 이름*'
                            value={userClientName}
                            onChange={(event) => {
                                setUserClientName(event.target.value);

                            }}
                        />
                    </FormControl>
                </Box>

                {/* 논의된 주제에 대한 간략한 메모 */}
                <Box sx={{ minWidth: 120, mt: 5 }}>
                    <InputLabel id="log">논의된 주제에 대한 간략한 메모 (최대 280자)*</InputLabel>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-controlled"
                            placeholder='논의된 주제에 대한 간략한 메모 (최대 280자)*'
                            value={userLog}
                            onChange={(event) => {
                                setUserLog(event.target.value);

                            }}
                        />
                    </FormControl>
                </Box>


                <Box sx={{ minWidth: 120, mt: 5 }}>
                    <Typography sx={{ mt: 2, mb: 4 }}>
                        *안전 보호/지원 요청 이 있으면 아래 항목을 "예"라고 답하세요.
                        안전 보호 문제와 지원 요청의 차이:
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 3 }}>
                        안전 보호 문제 (학대, 즉각적 피해):
                        <li>예: 폭력, 성폭행, 자살, 충동적인 생각 등</li>
                        <li>vcallhelp@gmail.com 에 즉시 아래 양식의 이메일 보내기:</li>
                        <li>“응급 - 봉사자 이름 - 고객 이름”</li>
                        <li>최대한 자세히 모든 정보와 왜 안전 보호 문제 라고 생각하는지 적어주세요.</li>
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 5 }}>
                        <li>www.vcall-korea.com 의 프로필 페이지의 “온라인 통화 기록 양식”을 완료하고 “후속 조치 요청” 양식을 아래 양식처럼 써주세요:</li>
                        <li>응급 - 안전보호</li>
                        <li>이유:</li>
                        <li>세부사항</li>
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 4 }}>
                        고객/간병인 지원 요청 (보호 문제는 아니지만 지원이 필요할떼):
                        <li>예: 재정, 움직임 도움, 장보기 등</li>
                        <li>www.vcall-korea.com 의 프로필 페이지의 “온라인 통화 기록 양식”을 완료하고 “후속 조치 요청” 양식을 지원 요청 사항, 이유, 그리고 원하는것을 자세히 써서 제출하세요.</li>
                    </Typography>


                </Box>



                {/* Safeguarding issue */}
                <Box sx={{ minWidth: 120, mt: 5 }}>
                    <InputLabel id="followup">후속 조치 요청*</InputLabel>
                    <Typography variant='subtitle1' sx={{ mt: 1, mb: 1 }}>위 문항을 "예" 라고 답하셨다면 최대한 자세히 무슨 지원이 필요한지 아래 적어주세요. 24시간 이내 답을 드리겠습니다.</Typography>

                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userSafeguarding}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(event) => {
                                setUserSafeguarding(event.target.value);

                            }}
                        >
                            <MenuItem value={"yes"}>예</MenuItem>
                            <MenuItem value={"no"}>아니오</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120, mt: 5 }}>
                    <InputLabel id="followup">후속 조치 요청 설명</InputLabel>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-controlled"
                            placeholder='후속 조치 요청 설명'
                            value={userSgexplanation}
                            onChange={(event) => {
                                setUserSgexplanation(event.target.value);

                            }}
                        />
                    </FormControl>
                </Box>


                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5, mb: 10 }}>
                    <Button onClick={handleFormSubmit} variant='contained' size='large'>제출하기</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default FeedbackFormVolunteer