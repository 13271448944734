import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from "react";
function Forum() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])
    return (
        <div >



            <Box sx={{ display: "flex", ml: 3, pt: 15, justifyContent: "space-between" }}>
                <Typography color={'primary.main'} variant='overline' sx={{
                    fontSize: isMobile ? '24px' : '30px', fontWeight: "bold"
                }} data-aos="fade-up" data-aos-duration="400" >FORUM - 전국 치매 가족 모임/게시판</Typography>

                {
                    isMobile ? null : <img width={80} height={80}
                        data-aos="fade-up" data-aos-duration="600"
                        style={{ marginRight: 10 }}
                        className="img-animation"
                        alt={"loading"}
                        src='./logo.png'></img >
                }


            </Box>


            <Box sx={{ m: 5, pb: 20 }} data-aos="fade-up" data-aos-duration="800">
                <Box sx={{ m: 2 }}>
                    <Typography sx={{ fontSize: '18px', mb: 2 }}>✲ 치매에 걸린 모든 사람을 위한 온라인 커뮤니티 입니다. 사람들은 하루 24시간 연중무휴로 단결하고 경험을 공유하며 지원을 받을 수 있습니다.</Typography>
                    <Typography sx={{ fontSize: '18px' }}>✲ VCall 의 홈페이지에서 “포럼”을 누르면 대한치매협회와 중앙치매센터의 지역별 치매가족모임을 찾을수 있습니다. 또한 지역별 전문 치매 병원, 요양원, 그리고 단체를 찾을수 있으니 유익하게 사용 하시기 바랍니다.</Typography>

                </Box>





                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://band.us/band/92072838"}>
                        VCall 고객/간병인 밴드
                    </Button>
                </Typography>

                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://band.us/band/92072855"}>
                        VCall 자원봉사자 밴드
                    </Button>
                </Typography>



                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 대한치매협회
                    </Typography>
                </Box>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/main/sub.html?pageCode=58"}>
                        자유게시판
                    </Button>
                </Typography>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://band.us/@dsa"}>
                        대한치매협회 네이버 밴드
                    </Button>
                </Typography>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"http://dementia.kr/main/sub.html?mstrCode=4"}>
                        치매네트워크/모임
                    </Button>
                </Typography>

                <Box sx={{ display: "flex", m: 2, mb: 1 }}>
                    <Typography color={'primary.main'} variant='overline' sx={{ pt: 5, pl: 3, fontSize: '26px', fontWeight: "bold" }}>
                        ✲ 중앙치매센터
                    </Typography>
                </Box>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/support/jajo_group.aspx"}>
                        치매 가족 모임
                    </Button>
                </Typography>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/support/ansimhosp_ContA.aspx"}>
                        치매 전문 병동 리스트
                    </Button>
                </Typography>
                <Typography sx={{ pl: 3, pt: 2, pr: 3 }} color="secondary.main">
                    <Button variant="outlined" target="_blank" rel="noopener noreferrer" href={"https://www.nid.or.kr/notification/newsletter_list.aspx?year=2023"}>
                        뉴스레터
                    </Button>
                </Typography>


            </Box>





        </div>
    );
}
export default Forum;