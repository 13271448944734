import { createTheme } from '@mui/material';

let theme = createTheme({

    palette: {
        primary: { main: "#25316D", contrastText: "#fff" },
        //Primary = main blue (e.g., background)
        secondary: { main: "#49608f", contrastText: "#fff" },
        //Secondary = header blue (emphasis text)
        tertiary: { main: "#B3BFB8", contrastText: "#fff" },

        vcall: {
            primary: {
                main: "#25316D"
            },
            secondary: {
                main: "#49608f",
            },
            tertiary: {
                main: "#B3BFB8",
            },
            light: {
                main: "rgb(244, 249, 244)"
            },
            lighter: {
                main: "#f7f6f2"
            },

        }



    },

    typography: {
        fontFamily: 'NanumSquareNeo-Variable, Arial, sans-serif', // Add fallback fonts

        body1: {
            color: "#333",
            lineHeight: 1.75,
            fontSize: "1.2rem"
        },
        body2: {
            color: "#333",
            lineHeight: 1.75,
            fontSize: "1.1rem"
        }
    },
});



export default theme