import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";

import { auth } from "../services/firebase";

const drawerWidth = 240;
const navItems = ['홈', '회사 소개', '도움 자료', '게시판'];
const navItemsPath = ['', 'aboutus', 'resources', 'forum'];


function Navbar(props) {
    const { window } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [user, loading, error] = useAuthState(auth);

    React.useEffect(() => {
        if (!user) {
            setAnchorEl(null);
        }
    }, [user]);

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const drawer = (
        <Box sx={{ textAlign: 'center' }} >
            < img width={80} height={80}
                className="img-animation"
                alt={"loading"}
                src='./logo.png'></img >

            <Divider />
            {navItems.map((item, idx) => (
                <Link key={idx.toString()} to={navItemsPath[idx]} style={{ textDecoration: 'none', color: "black" }}>
                    <ListItemButton onClick={handleClose}>
                        <Typography variant="body2" color={"primary"}>
                            {item}
                        </Typography>
                    </ListItemButton>
                </Link>
            ))
            }
            {user ? (
                <>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => { handleMenu(e) }}
                        color="inherit"
                    >
                        <Avatar src={user.photoURL} sx={{ width: 24, height: 24 }} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Link to="/login" style={{ textDecoration: 'none', color: "black" }}>
                                프로필
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={() => { auth.signOut() }}>로그아웃</MenuItem>

                    </Menu>
                </>
            ) :
                <Link to="/login" style={{ textDecoration: 'none', color: "black" }}>
                    <Button>
                        <Typography color="primary">로그인</Typography>
                    </Button>
                </Link>
            }
        </Box >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar component="nav" sx={{ bgcolor: "#475a7a", boxShadow: "rgba(99,99,99,.12) 2px 2px 3px 2px", transition: "1000ms", }} >
                <Toolbar sx={{ height: '70px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ ml: 1, display: { sm: 'none' } }}
                    >
                        <MenuIcon color='white' />
                    </IconButton>
                    <Typography
                        variant="overline"
                        component="div"
                        color="black"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontSize: "16px" }}
                    >
                        <Link to={navItemsPath[0]} style={{ textDecoration: "none", display: "flex", flexDirection: "row" }}>
                            < img width={80} height={80}
                                className="img-animation"
                                alt={"loading"}
                                style={{ marginTop: -5 }}
                                src='./logo_white.png'></img >

                        </Link>

                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, transition: '2000ms', }}  >
                        {navItems.map((item, idx) => (
                            <Link key={idx.toString()} to={navItemsPath[idx]} style={{ textDecoration: 'none', color: "black", }}>
                                <Button key={item} >
                                    <Typography color="#ddd" variant='body2'>{item}</Typography>
                                </Button>
                            </Link>
                        ))}

                        {user ? (
                            <>

                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={(e) => { handleMenu(e) }}
                                    color="inherit"
                                >
                                    <Avatar src={user.photoURL} sx={{ width: 24, height: 24 }} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Link to="/login" style={{ textDecoration: 'none', color: "#ddd" }}>
                                            프로필
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => { auth.signOut() }}>로그아웃</MenuItem>

                                </Menu>
                            </>
                        ) :
                            <Link to="/login" style={{ textDecoration: 'none', color: "#ddd" }}>
                                <Button>
                                    <Typography color="#ddd">로그인</Typography>
                                </Button>
                            </Link>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </>

    );
}

export default Navbar;
