import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

import "../App.css";

function InitialLoading() {
    return (
        <Box width={"100vw"} height={"100vh"} backgroundColor={"#edf1f7"} display={"flex"} flexDirection={'column'} justifyContent="center" alignItems={'center'}>
            <div data-aos="zoom-out-down" data-aos-duration="1200">
                < img
                    alt={"loading"}
                    width={250}
                    src='./logo.png' ></img >

            </div>
            <Box mt={5} textAlign={"center"} >
                <Box m={5} display={"flex"} justifyContent={"center"}>
                    <CircularProgress size="1.75rem" color='secondary' />
                </Box>
            </Box>
        </Box >
    );
}

export default InitialLoading;
