import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import FeedbackFormClient from '../components/FeedbackFormClient';
import FeedbackFormVolunteer from '../components/FeedbackFormVolunteer';
import { auth, db } from "../services/firebase";

import "./Dashboard.css";

function Profile() {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const [userData, setUserData] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])
    const fetchUserName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            console.log("Query:", q);

            const querySnapshot = await getDocs(q);
            console.log("Query snapshot:", querySnapshot);

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                const data = doc.data();
                console.log("User data:", data);
                setName(data.name);
                setUserData(data)
            } else {
                console.log("No matching documents found.");
            }
        } catch (err) {
            console.error(err);
            console.log("An error occurred while fetching user data in Profile.js");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) {
            console.log("no user")
            navigate("/login")
        };
        (async () => {
            setIsLoading(true)
            await fetchUserName()
            setIsLoading(false)

        })();


    }, [user, loading]);

    if (!user || loading || isLoading) return (

        <Box sx={{ height: "100vh" }} data-aos="fade-down">
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                <Box>

                    <Typography variant="h5" sx={{ mt: 20 }}>사용자 정보 확인 중...</Typography>
                </Box>
                <Box sx={{ mt: 20 }}>
                    <CircularProgress />
                </Box>
            </Box>
        </Box>


    )

    if (userData.role === "client") {
        return (
            <Box sx={{ pb: 20 }} >
                <Box sx={{ display: "flex", justifyContent: "center", }}>
                    <Typography variant="h5" sx={{ mt: 10, fontWeight: "bold" }} color={'primary.main'}>환영합니다 클라이언트, {name}님!</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", }}>

                    <FeedbackFormClient userData={userData} />
                </Box>



            </Box>
        )
    }
    else {

        return (
            <Box sx={{ pb: 20 }}>
                <Box sx={{ display: "flex", justifyContent: "center", }}>
                    <Typography variant="h5" sx={{ mt: 10, fontWeight: "bold" }} color={'primary.main'}>환영합니다 자원봉사자, {name}님!</Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", }}>

                    <FeedbackFormVolunteer userData={userData} />
                </Box>



            </Box>
        );
    }
}
export default Profile;